import api from '@/api'
import { Page, Burger } from '@/api/queries'

export default {
  state: {
    welcome: undefined,
    intro: undefined,
    kit: undefined,
    ids: {
      welcome: 263,
      intro: 264,
      kit: 265,
      gallery: 349
    },
    pages: {},
    burger: [
      { name: 'home' },
      { name: 'introduction' }
    ],
    visited: localStorage.getItem('visited') === 'true'
  },

  mutations: {
    'SET_PAGE' (state, { slug, page }) {
      state[slug] = page
    },

    'SET_PAGE_FROM_ID' (state, { id, page }) {
      state.pages[id] = page
    },

    'SET_BURGER' (state, burger) {
      state.burger = [...state.burger, ...burger]
    },

    'SET_VISITED' (state, visited) {
      state.visited = visited
      localStorage.setItem('visited', visited)
    }
  },

  actions: {
    async 'QUERY_PAGE' ({ state, commit, dispatch, getters }, slug) {
      if (state[slug] !== undefined) return state[slug]
      return api.query(Page, { id: state.ids[slug] }).then(data => {
        dispatch('PARSE_PAGE', data.page)
        commit('SET_PAGE', { slug, page: data.page })
        return state[slug]
      })
    },

    async 'QUERY_PAGE_BY_ID' ({ state, commit, dispatch, getters }, id) {
      if (state.pages[id] !== undefined) return state.pages[id]
      return api.query(Page, { id }).then(data => {
        dispatch('PARSE_PAGE', data.page)
        commit('SET_PAGE_FROM_ID', { id, page: data.page })
        return state.pages[id]
      })
    },

    async 'GET_BURGER' ({ state, commit }) {
      if (state.burger.length > 2) return state.burger
      return api.query(Burger).then(data => {
        const burger = data.burger.sort((a, b) => {
          return a.poid > b.poid ? 1 : -1
        }).map(page => {
          return { name: 'pages', id: page.id, title: page.title }
        })
        commit('SET_BURGER', burger)
        return burger
      })
    },

    'PARSE_PAGE' ({ rootState }, node) {
      if (node.notes) {
        node.notes.forEach(note => {
          if (note.links && note.links.length) {
            note.links = note.links.map(link => {
              if (!(link.id in rootState.nodes) || rootState.nodes[link.id].dataLevel < 1) {
                this.commit('ADD_NODES', [[link], -1])
              }
              return rootState.nodes[link.id]
            })
          }
        })
      }
      return node
    }
  },

  getters: {
    visited: state => state.visited,
    burger: state => state.burger
  }
}
