import Home from '@/pages/Home'


export default [
  {
    name: 'home',
    path: '/',
    component: Home
  },

  {
    name: 'introduction',
    path: '/intro',
    component: () => import(/* webpackChunkName: "intro" */ '@/pages/Introduction')
  },

  {
    name: 'contact',
    path: '/contact',
    component: () => import(/* webpackChunkName: "contact" */ '@/pages/Contact')
  },

  {
    name: 'library',
    path: '/library',
    components: {
      default: () => import(/* webpackChunkName: "library" */ '@/pages/Library'),
      options: () => import(/* webpackChunkName: "library" */ '@/pages/library/LibraryOptions')
    },
    props: {
      default: ({ query }) => ({ query })
    }
  },

  {
    name: 'kit-view',
    path: '/kit/:id',
    props: true,
    component: () => import(/* webpackChunkName: "kit" */ '@/pages/kit/KitView')
  },

  {
    name: 'kit',
    path: '/kit',
    component: () => import(/* webpackChunkName: "kit" */ '@/pages/kit/KitList')
  },

  {
    name: 'gallery-view',
    path: '/gallery/:id',
    props: true,
    component: () => import(/* webpackChunkName: "gallery" */ '@/pages/Gallery')
  },

  {
    name: 'gallery',
    path: '/gallery',
    component: () => import(/* webpackChunkName: "gallery" */ '@/pages/Gallery')
  },

  {
    name: 'pages',
    path: '/pages/:id',
    props: true,
    component: () => import(/* webpackChunkName: "blog" */ '@/pages/Page')
  },

  {
    name: 'debug',
    path: '/debug',
    component: () => import(/* webpackChunkName: "debug" */ '@/pages/Debug')
  },

  {
    name: 'notfound',
    path: '/404',
    alias: '*',
    component: () => import(/* webpackChunkName: "404" */ '@/pages/NotFound.vue')
  }
]
