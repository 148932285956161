<template>
  <div class="legend-toggle">
    <b-button
      variant="dark"
      class="btn-legend-toggle"
      :class="visible ? null : 'collapsed'"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="collapse-legend-toggle"
      @click="onBtnClick"
    >
      <span>?</span>
    </b-button>

    <b-collapse id="collapse-legend-toggle" v-model="visible">
      <div class="collapse-body" ref="body">
        <slot name="default" />
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'LegendToggle',

  data () {
    return {
      visible: false
    }
  },

  methods: {
    onBtnClick () {
      this.visible = !this.visible
      if (this.visible) {
        document.addEventListener('mousedown', this.onDocumentClick, { capture: true })
      }
    },

    onDocumentClick (e) {
      if (this.$refs.body.contains(e.target)) return
      document.removeEventListener('mousedown', this.onDocumentClick, { capture: true })
      if (!e.target.classList.contains('btn')) {
        this.visible = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.legend-toggle {
  position: absolute;
  top: 15px;
  left: 15px;

  @include media-breakpoint-up($size-bp) {
    top: 30px;
    left: 30px;
  }
}

.btn-legend-toggle {
  display: block;
  width: $btn-size;
  height: $btn-size;
  padding: 0;
  border: 0;
  font-weight: $font-weight-bold;
  // font-size: 1.7rem;
  line-height: 1;

  margin-bottom: 10px;

  span {
    pointer-events: none;
  }
}

.collapse {
  transition: none;
}

.collapse-body {
  max-width: 500px;
  margin-right: 15px;
  border: $line;
  background-color: $white;
  padding: $node-card-spacer-y $node-card-spacer-x;

  @include media-breakpoint-up($size-bp) {
    margin-right: 30px;
  }
}
</style>
