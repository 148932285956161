import Vue from 'vue'
import Vuex from 'vuex'

import nodes from './nodes'
import library from './modules/library'
import kit from './modules/kit'
import gallery from './modules/gallery'
import pages from './modules/pages'

Vue.use(Vuex)

export default new Vuex.Store({
  ...nodes,
  modules: {
    library,
    kit,
    gallery,
    pages
  }
})
