<template>
  <b-sidebar
    class="side-view no-events-container"
    v-bind="$attrs" v-on="$listeners"
    no-header
    :id="id"
    :body-class="{ paddings: !noCross }"
    :visible="visible"
  >
    <template #default="{ hide, visible: visible2 }">
      <div v-if="!noCross" class="btn-close-wrapper">
        <button-close @click="hide()" />
      </div>
      <slot name="default" v-bind="{ hide, visible: visible2 }" />
    </template>
  </b-sidebar>
</template>

<script>
export default {
  name: 'SideView',

  props: {
    id: { type: String, required: true },
    noCross: { type: Boolean, default: false },
    visible: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.side-view {
  position: absolute;
  height: 100%;

  ::v-deep {
    .b-sidebar {
      position: absolute;
      width: auto;

      @media (orientation: portrait) {
        width: 100%;
      }

      @media (orientation: landscape)  {
        max-width: 50%;

        &:not(.b-sidebar-right) {
          border-right: $line;
        }

        &.b-sidebar-right {
          border-left: $line;
        }
      }
    }

    .b-sidebar-body.paddings {
      padding: $node-view-spacer-sm-y $node-view-spacer-sm-x;
      @include media-breakpoint-up($layout-bp) {
        padding: $node-view-spacer-y $node-view-spacer-x;
      }
    }
  }

  .btn-close-wrapper {
    position: sticky;
    z-index: 2;
    height: 1.75rem;
    width: 1.75rem;
    top: 0;
    right: 0;
    float: right;
    margin-top: -1rem;

    .btn-close {
      position: absolute;
      padding: 0.5rem;
      width: 2.25rem;
      height: 2.25rem;
      top: -.5rem;
      right: -.5rem;
      background-color: $white;
    }
  }
}
</style>
