<template>
  <b-button
    v-on="$listeners"
    v-bind="$attrs"
    variant="link"
    class="btn-image"
  >
    <span
      class="btn-image-wrapper"
      :style="`--url: url(${image.url});`"
    >
      <slot name="default" />
    </span>
  </b-button>
</template>

<script>
export default {
  name: 'ButtonImage',

  props: {
    image: { type: Object, required: true }
  }
}
</script>

<style lang="scss" scoped>
.btn-image {
  padding: 0;
  width: 135px;
  height: 135px;
  border: 0;
  transition: 100ms;

  @media (orientation: landscape) {
    width: 250px;
    height: 250px;

    &:hover,
    &.hover,
    &:focus,
    &.focus {
      width: 400px;
      height: 400px;
    }
  }

  &-wrapper {
    border: $line;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    display: inline-block;
    background-image: var(--url);
    background-size: cover;
  }
}
</style>
