<template>
  <b-button
    v-on="$listeners"
    v-bind="$attrs"
    variant="link"
    class="btn-url"
    :href="link.url"
    target="_blank"
  >
    <span class="sr-only">{{ link.title }}</span>
    <svg aria-hiden="true" viewBox="0 0 39 39">
      <path d="M 18,6 h-12 v27 h27 v-12" />
      <path d="M 24,6 h9 v9" />
      <path d="M 33,6 13.5,25.5" />
    </svg>
  </b-button>
</template>

<script>
export default {
  name: 'ButtonUrl',

  props: {
    link: { type: Object, required: true }
  }
}
</script>

<style lang="scss" scoped>
.btn-url {
  padding: 0;
  width: 20px;
  height: 20px;
  border: 0;
  line-height: 0;

  position: relative;
  top: -3px;

  @include media-breakpoint-up($size-bp) {
    top: -5px;
    width: 40px;
    height: 40px;
  }

  &:hover,
  &:focus {
    path {
      stroke: $black;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: inline;
  }

  path {
    fill: none;
    stroke: lighten($black, 15%);
    stroke-width: 4px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}
</style>
