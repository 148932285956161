<template>
  <div
    class="node-view-footer" :class="'node-view-footer-' + mode"
  >
    <div class="node-view-footer-wrapper w-100">
      <div class="node-view-footer-base">
        <div class="tags mb-n2">
          <b-badge
            v-for="tag in node.tags" :key="tag.id"
            variant="dark" pill class="mr-2 mb-2"
          >
            {{ tag.name }}
          </b-badge>
        </div>

        <b-button
          v-if="debug"
          variant="kit" class="btn-edit"
          :href="`/api/node/${node.id}/edit?destination=/api/node/${node.id}`" target="_blank"
        >
          Éditer
        </b-button>

        <div v-if="mode === 'view' && node.siblings && node.siblings.length">
          <b-button :id="'siblings-' + node.id" variant="depart" class="btn-shadow siblings">
            {{ $t('siblings') }}
          </b-button>

          <b-popover
            :target="'siblings-' + node.id"
            triggers="focus" placement="top" boundary="viewport"
            custom-class="popover-siblings"
          >
            <ul>
              <li v-for="sibling in node.siblings" :key="sibling.id">
                <node-view-title
                  :node="sibling"
                  link edition block
                  @open-node="onOpenSibling(sibling.id, 'siblings-' + node.id)"
                />
                <node-view-body
                  v-bind="{ node: sibling, type: sibling.type || 'ref', mode: 'card' }"
                />
                <div class="tags mb-n2 mt-2" v-if="sibling.tags">
                  <b-badge
                    v-for="tag in sibling.tags" :key="tag.id"
                    variant="dark" pill class="mr-2 mb-2"
                  >
                    {{ tag.name }}
                  </b-badge>
                </div>
              </li>
            </ul>
          </b-popover>
        </div>
      </div>

      <div v-if="mode === 'card' && preview" class="mt-2">
        <b-button @click="onOpenSelf()" variant="depart" class="btn-shadow btn-read">
          {{ $t('text.read') }}
        </b-button>
      </div>

      <div v-if="mode === 'view' && showOrigin" class="mt-2">
        <b-button @click="onOpenSelf()" variant="depart" class="btn-shadow btn-read">
          {{ $t('text.read-origin') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { toCommaList } from '@/helpers/common'
import { getRelation } from '@/store/utils'
import { NodeViewTitle, NodeViewBody } from '@/components/nodes'


export default {
  name: 'NodeViewFooter',

  components: {
    NodeViewTitle,
    NodeViewBody
  },

  props: {
    node: { type: Object, required: true },
    type: { type: String, required: true },
    mode: { type: String, required: true },
    preview: { type: Boolean, required: true },
    showOrigin: { type: Boolean, required: true }
  },

  computed: {
    ...mapGetters(['debug']),

    authors () {
      const authors = this.node.authors
      if (!authors) return 'Pas d\'auteur⋅rices'
      return authors.map(({ name }) => name).join(', ')
    }
  },

  methods: {
    toCommaList,

    onOpenSibling (parentId, popoverBtnId) {
      this.$root.$emit('bv::hide::popover', popoverBtnId)
      this.$parent.$emit('open-node', { parentId })
    },

    onOpenSelf () {
      this.$parent.$emit('open-node', getRelation(this.node))
    }
  },

  created () {
    if (this.mode === 'view' && this.node.siblings) {
      // Query partial data for sibling nodes
      const ids = this.node.siblings.map(sibling => sibling.id)
      this.$store.dispatch('GET_NODES', { ids, dataLevel: 'initial' })
    }
  }
}
</script>

<style lang="scss">
.node-view-footer {
  margin-top: auto;
  font-family: $font-family-base;
  font-size: 0.9rem;

  @include media-breakpoint-up($size-bp) {
    font-size: 1rem;
  }

  &-base {
    display: flex;
    align-items: flex-start;

    .siblings {
      display: block;
      white-space: nowrap;
      margin-left: 1rem;
    }
  }

  .btn-read,
  .btn-edit {
    pointer-events: auto;
  }

  .btn {
    font-size: inherit;
  }
}

.popover-siblings {
  background-color: $white;
  max-width: 350px;

  .popover-body {
    border: $line;
    max-height: 70vh;
    overflow: auto;
    color: $black;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 2rem;

        &:last-of-type {
          margin-bottom: 18px;
        }
      }

      h6 {
        margin: 0;
      }
    }

    .edition {
      font-size: .7rem;
    }

    .node-view-body {
      font-size: 1rem;
      padding: 0;

      .media-container {
        display: none;
      }
    }

    .badge {
      font-size: 0.875rem;
    }
  }
}
</style>
