<template>
  <b-button
    v-on="$listeners"
    v-bind="$attrs"
    variant="link"
    class="btn-close"
    aria-label="Fermer"
  >
    <svg aria-hiden="true" viewBox="0 0 20 20">
      <path d="m 2.5,2.5 L17.5,17.5" />
      <path d="m 17.5,2.5 L2.5,17.5" />
    </svg>
  </b-button>
</template>

<script>
export default {
  name: 'ButtonClose'
}
</script>

<style lang="scss" scoped>
.btn-close {
  padding: 3px;
  width: $btn-size;
  height: $btn-size;
  border: 0;
  line-height: 0;

  &:hover,
  &:focus {
    path {
      stroke: $black;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  path {
    fill: none;
    stroke: lighten($black, 15%);
    stroke-width: 4px;
    stroke-linecap: round;
  }
}
</style>
