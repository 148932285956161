import axios from 'axios'
import { print } from 'graphql/language/printer'

import { Node, Nodes, AllNodesOfVariant, NodesTrees } from '@/api/queries'
import * as nodesFragments from '@/api/fragments'


const api = axios.create({
  baseURL: window.location.origin + '/api/gql',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})


export default {
  getQueryString (query, levels) {
    const FRAGMENTS_NAMES = ['NodeInitial', 'NodePartial', 'NodeFull']
    const names = levels.map(level => FRAGMENTS_NAMES[level])
    const fragments = names.map(name => '...' + name).join('\n')
    const fragmentsDefs = names.map(name => print(nodesFragments[name])).join('\n')
    return print(query).replace('FRAGMENTS', fragments) + fragmentsDefs
  },

  query (query, variables = {}) {
    return api
      .post('', { query: print(query), variables })
      .then(response => {
        return response.data.data
      })
  },

  queryNodes (ids, levels) {
    return api.post('', {
      query: this.getQueryString(Nodes, levels),
      variables: { ids }
    }).then(response => response.data.data)
  },

  queryNode (id, levels) {
    return api.post('', {
      query: this.getQueryString(Node, levels),
      variables: { id }
    }).then(response => response.data.data)
  },

  queryAllNodes (variantId, levels) {
    return api.post('', {
      query: this.getQueryString(AllNodesOfVariant, levels),
      variables: { variantId }
    }).then(response => response.data.data)
  },

  queryRecursiveNodes (ids, depth = 4) {
    const baseQuery = print(NodesTrees)
    const prodFragment = `
      ... on Textprod {
        #parents: text_de_depart {
        #  id
        #}

        siblings: text_en_rebond {
          id
          FRAGMENT_REF
        }
      }
    `
    const refFragment = `
      ... on Textref {
        siblings: text_en_rebond {
          id
          FRAGMENT_REF
        }

        children: text_produits {
          id
          FRAGMENT_PROD
        }

        creations: field_creations {
          id

          siblings: rebonds {
            id
          }
        }
      }
    `

    function formatFragment (str, depth) {
      if (depth > 0) {
        return formatFragment(
          str.replaceAll('FRAGMENT_REF', refFragment).replaceAll('FRAGMENT_PROD', prodFragment),
          --depth
        )
      } else {
        return str.replaceAll('FRAGMENT_REF', '').replaceAll('FRAGMENT_PROD', '')
      }
    }

    return api.post('', {
      query: baseQuery.replace('FRAGMENT', formatFragment(refFragment + prodFragment, depth)),
      variables: { id: ids[0] }
    }).then(response => response.data.data.node)
  }
}
