<template>
  <div class="view-home">
    <div class="home-sections">
      <div class="home-library" @click="$router.push('/library')">
        <div class="wrapper" />
        <b-button :to="{ name: 'library' }" variant="dark">
          {{ $t('sections.library') }}
        </b-button>
      </div>

      <div class="home-kit" @click="$router.push('/kit')">
        <div class="wrapper" />
        <b-button :to="{ name: 'kit' }" variant="kit">
          {{ $t('sections.kit') }}
        </b-button>
      </div>

      <div class="home-creations" @click="$router.push('/gallery')">
        <div class="wrapper" />
        <b-button :to="{ name: 'gallery' }" variant="creation">
          {{ $t('sections.gallery') }}
        </b-button>
        <div
          v-for="(item, i) in [['top', 'left'], ['top', 'right'], ['bottom', 'right'], ['bottom', 'left']]" :key="i"
          class="home-creations-circle" :class="item"
        />
      </div>
    </div>

    <div v-if="visited === false" class="page-popup">
      <div class="page-popup-container h-100">
        <b-overlay :show="popup === undefined" z-index="0" class="h-100">
          <page-view
            v-if="popup"
            :page="popup" slug="welcome"
            @close="onPopupClose()"
          />
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { PageView } from '@/components/layouts'


export default {
  name: 'Home',

  components: {
    PageView
  },

  data () {
    return {
      popup: undefined
    }
  },

  computed: {
    ...mapGetters(['visited'])
  },

  methods: {
    onPopupClose () {
      this.$store.commit('SET_VISITED', true)
    }
  },

  async created () {
    if (this.visited === false) {
      this.popup = await this.$store.dispatch('QUERY_PAGE', 'welcome')
    }
  }
}
</script>

<style lang="scss" scoped>
.view-home {
  position: relative;
  &, .home-sections {
    width: 100%;
    height: 100%;
  }

  .home-sections > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    .wrapper {
      position: absolute;
      opacity: 0.5;
      height: 100%;
      width: 100%;
      pointer-events: none;
    }

    @include media-breakpoint-up($size-bp) {
      &:not(:hover) {
        .wrapper,
        .btn {
          display: none;
        }
      }
    }
  }

  @include media-breakpoint-down($size-bp-down) {
    .home-sections {
      display: flex;
      flex-direction: column;

      > div {
        height: 100%;
      }
    }
  }

  @include media-breakpoint-up($size-bp) {
    .home-sections {
      display: grid;
      grid-template: 'a b'
      'a c';
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr);
    }

    .home-library {
      grid-area: a;
    }
    .home-kit {
      grid-area: b;
    }
    .home-creations {
      grid-area: c;
    }
  }

  .btn {
    font-family: $font-family-text;
    border-color: $black;
    padding: 0 1rem;
    font-size: 1.75rem;
    @include media-breakpoint-up($size-bp) {
      font-size: 2.5rem;
    }
    z-index: 1;
  }

  .home-library {
    background-image: url('~@/assets/imgs/home-library.svg');
    background-size: cover;
    background-position: center;
    border-bottom: $line;
    @include media-breakpoint-up($size-bp) {
      border-bottom: 0;
      border-right: $line;
    }

    .wrapper {
      background-color: $white;
    }
  }

  .home-kit {
    background-image: url('~@/assets/imgs/home-kit.svg');
    background-size: cover;

    .wrapper {
      background-color: lighten(theme-color('kit'), 15%);
    }
  }

  .home-creations {
    background-image: url('~@/assets/imgs/home-creations.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-top: $line;

    @include media-breakpoint-up($size-bp) {
      background-size: cover;
    }

    .wrapper {
      background-color: theme-color('creation');
    }

    &-circle {
      position: absolute;
      z-index: -1;
      width: 7vmin;
      height: 7vmin;
      background-color: #ccc;
      border-radius: 100%;

      @include media-breakpoint-up($size-bp) {
        width: 10vmin;
        height: 10vmin;
      }

      &.top {
        top: 0;
      }
      &.bottom {
        bottom: 0;
      }
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
    }
  }
}

.page-popup {
  border: $line;
  background-color: $white;
  position: absolute;
  width: 100%;
  z-index: 2;

  &-container {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  @include media-breakpoint-down($size-bp-down) {
    top: -2px;
    height: calc(100% + 2px);
    margin-bottom: -2px;
  }

  @include media-breakpoint-up($size-bp) {
    height: 80%;
    width: 80%;
    left: 10%;
    top: 50%;
    transform: translate(0, -50%);
  }
}
</style>
