export function trim (str) {
  if (!str) return str
  return str.replace(/^\s+|\s+$/g, '')
}


export function toCommaList (arr, key = 'name') {
  if (!arr) return
  return arr.map(item => item[key]).join(', ')
}

export function orderByVariant (arr) {
  const orderedVariants = [
    'lecture',
    'reflexion',
    'critique',
    'sensible',
    'echo',
    'kit',
    'creation'
  ]
  return [...arr].sort((a, b) => {
    return orderedVariants.indexOf(a.variant) > orderedVariants.indexOf(b.variant)
  })
}


export function shuffle (a) {
  let j, x, i
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1))
    x = a[i]
    a[i] = a[j]
    a[j] = x
  }
  return a
}
