<template>
  <div id="app">
    <main-header />

    <main id="main">
      <router-view :key="$route.path" />
    </main>

    <nodes-history />
  </div>
</template>

<script>
import MainHeader from '@/pages/_partials/MainHeader'
import NodesHistory from '@/pages/_partials/NodesHistory'


export default {
  name: 'App',

  components: {
    MainHeader,
    NodesHistory
  },

  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, try to get one from the route name.
      title: this.$t('sections.' + this.$route.name, ''),
      // all titles will be injected into this template
      titleTemplate: '%s | ' + this.$t('title')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/app.scss';
</style>

<style lang="scss" scoped>
</style>
