<template>
  <div
    id="preview-zone"
    class="preview-zone" :class="{ 'no-events-container': value === null }"
    @mousedown="onZoneClick"
  >
    <b-popover
      v-for="node in nodes" :key="'popover-' + node.id"
      custom-class="popover-node-preview"
      :target="'preview-node-' + node.id" container="preview-zone"
      placement="auto" triggers="manual"
    >
      <node-view
        :node="node"
        mode="card" preview
        @open-node="$emit('open-node', $event)"
        @open-child="$emit('open-node', { parentId: node.id, ...$event })"
      />
    </b-popover>
  </div>
</template>

<script>
import { NodeView } from '@/components/nodes'


export default {
  name: 'NodePreviewZone',

  components: {
    NodeView
  },

  props: {
    value: { type: Object, default: null },
    nodes: { type: Array, required: true }
  },

  methods: {
    onZoneClick (e) {
      if (this.value) {
        if (e.target.id !== 'preview-zone') return
        this.$root.$emit('bv::hide::popover', 'preview-node-' + this.value.id)
        this.$emit('input', null)
      }
    }
  }
}
</script>

<style lang="scss">
.preview-zone {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.popover-node-preview {
  max-width: 100%;

  .popover-body {
    padding: 0;
  }

  .node-view {
    width: $node-card-width-sm;
    max-width: 100%;
    max-height: 100%;

    @include media-breakpoint-up($size-bp) {
      width: $node-card-width;
    }

    &-wrapper {
      height: $node-card-height-sm;

      @include media-breakpoint-up($size-bp) {
        height: $node-card-height;
      }
    }
  }
}
</style>
