<template>
  <header class="main-header">
    <b-navbar class="main-navbar">
      <div class="main-navbar-brand">
        <b-dropdown variant="link" no-caret>
          <template #button-content>
            <svg class="burger-icon" viewBox="0 0 16 16">
              <path v-for="n in [2, 8, 14]" :key="n" :d="`M 2,${n} h12`" />
            </svg>
            <span class="sr-only">Menu</span>
          </template>

          <b-dropdown-item
            v-for="link in mainRoutes" :key="link.to"
            :to="{ name: link.to }" class="d-tb-none" :class="'dropdown-item-' + link.variant"
          >
            {{ $t('sections.' + link.to) }}
          </b-dropdown-item>

          <b-dropdown-item
            v-for="page in burger" :key="page.id"
            :to="{ name: page.name, params: page.id ? { id: page.id } : {} }"
          >
            {{ page.id ? page.title : $t('sections.' + page.name ) }}
          </b-dropdown-item>
        </b-dropdown>

        <b-navbar-brand :to="{ name: 'home' }">
          {{ $t('title') }}
        </b-navbar-brand>
      </div>

      <nav class="nav-list d-none d-tb-block">
        <ul>
          <li v-for="link in mainRoutes" :key="link.to">
            <b-button :to="{ name: link.to }" :active="routeIsActive(link.to)" :variant="link.variant">
              {{ $t('sections.' + link.to) }}
            </b-button>
          </li>
        </ul>
      </nav>

      <b-button
        v-if="$route.name === 'library'"
        class=""
        :class="optionsVisible ? null : 'collapsed'"
        :aria-expanded="optionsVisible ? 'true' : 'false'"
        aria-controls="collapse-options"
        @click="optionsVisible = !optionsVisible"
        variant="outline-dark"
      >
        Options <span class="collapse-icon" :class="{ collapsed: !optionsVisible }">></span>
      </b-button>
    </b-navbar>

    <b-collapse id="collapse-options" v-model="optionsVisible">
      <router-view name="options" />
    </b-collapse>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'


export default {
  name: 'MainHeader',

  data () {
    return {
      mainRoutes: [
        { to: 'library', variant: 'dark' },
        { to: 'kit', variant: 'kit' },
        { to: 'gallery', variant: 'creation' }
      ]
    }
  },

  computed: {
    ...mapGetters(['burger']),

    optionsVisible: {
      get () { return this.$store.state.optionsVisible },
      set (value) {
        this.$store.commit('UPDATE_OPTIONS_VISIBILITY', value)
      }
    }
  },

  methods: {
    routeIsActive (to) {
      return to === 'gallery'
        ? ['gallery', 'gallery-view'].includes(this.$route.name)
        : this.$route.name === to
    }
  },

  created () {
    this.$store.dispatch('GET_BURGER')
  }
}
</script>

<style lang="scss" scoped>
.main-header {
  width: 100%;
  border-bottom: $line;
}


.main-navbar {
  padding: $header-spacer-sm;
  font-size: $font-size-sm;
  justify-content: space-between;

  @include media-breakpoint-up($layout-bp) {
    height: $header-height;
    padding: 0 $header-spacer;
  }

  ::v-deep {
    .dropdown-toggle {
      padding: 0;
      border: 0;
      line-height: 1;
      padding: 0 .5rem;
      margin-left: -.5rem;

      @include media-breakpoint-up($size-bp) {
        padding: 0 .75rem;
        margin-left: -.75rem;
      }
    }

    .dropdown-menu {
      border: 0;
      z-index: 1500;

      .dropdown-item {
        padding: .25rem $header-spacer-sm;

        @include media-breakpoint-up($layout-bp) {
          padding: .25rem $header-spacer;
        }
      }

      @include media-breakpoint-down($size-bp-down) {
        width: 100vw;
      }

      @each $variant in 'dark', 'kit', 'creation' {
        .dropdown-item-#{$variant} {
          background-color: theme-color($variant);

          a:not(:hover):not(:focus) {
            color: if($variant == 'dark', $white, $black);
          }
        }
      }
    }
  }

  .burger-icon {
    width: 10px;
    height: 10px;
    display: block;

    path {
      stroke: $black;
      stroke-width: 2px;
      stroke-linecap: round;
    }

    @include media-breakpoint-up(md) {
      width: 16px;
      height: 16px;

      path {
        stroke-width: 2.5px;
      }
    }
  }

  &-brand {
    display: flex;

    .navbar-brand {
      font-size: inherit;
      font-weight: $font-weight-bold;
      text-decoration: none;
      line-height: 1;
      padding: 0;

      @include media-breakpoint-up(md) {
        font-size: 1.3125rem;
      }
    }
  }

  .nav-list {
    margin-left: auto;
    margin-right: 1rem;

    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;

      ul {
        flex-direction: column;
        width: 100%;
      }

      li {
        height: 22px;

        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }

      a {
        height: 22px;
        width: 100%;
        text-align: left;
      }
    }

    @include media-breakpoint-up(sm) {
      ul {
        height: 26px;
      }

      li:not(:last-child) {
        margin-right: 7px;
      }
    }

    a:not(.active) {
      opacity: .25;
    }
  }

  .btn {
    font-size: inherit;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }
}

// #collapse-options {
//   @include media-breakpoint-down(sm) {
//     position: absolute;
//     z-index: 11;
//     width: 100%;
//     border-bottom: $line;
//   }
// }
</style>
