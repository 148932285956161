<template>
  <div class="border p-3">
    <div>
      <span class="font-weight-bold">{{ component.$options.name }}</span>
      PROPS: {{ component.$props }}
    </div>
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'ComponentDebug',

  props: {
    component: { type: Object, required: true }
  }
}
</script>

<style lang="scss" scoped>
</style>
