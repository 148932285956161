<template>
  <b-button
    v-on="$listeners"
    v-bind="$attrs"
    variant="depart"
    class="btn-expand on-hover"
    :aria-label="expanded ? 'Réduire' : 'Agrandir'"
  >
    <svg aria-hidden="true" viewBox="0 0 75 70">
      <path v-if="expanded" d="M20,30 h10 v-10 M45,20 v10 h10 M55,40 h-10 v10 M30,50 v-10 h-10" />
      <path v-else d="M20,30 v-10 h10 M45,20 h10 v10 M55,40 v10 h-10 M30,50 h-10 v-10" />
    </svg>

    <span v-if="artwork" class="btn-artwork">Voir l'œuvre</span>
  </b-button>
</template>

<script>
export default {
  name: 'ButtonExpand',

  props: {
    expanded: { type: Boolean, default: false },
    artwork: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
$light-black: lighten($black, 20%);

.btn-expand {
  padding: 0;
  width: 75px;
  height: 70px;
  border: 0;
  border-radius: 0 !important;
  line-height: 0;

  &:hover,
  &:focus {
    path {
      stroke: $black;
    }

    .btn-artwork {
      border-color: $black;
      box-shadow: 3px 5px 0 $black;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  path {
    fill: none;
    stroke: $light-black;
    stroke-width: 4px;
    stroke-linejoin: round;
    stroke-linecap: round;
  }

  .btn-artwork {
    position: absolute;
    bottom: -35px;
    left: -22.5px;
    width: 120px;
    height: 30px;
    line-height: 1.5;
    background-color: $white;
    border: 2px solid $light-black;
    border-radius: 50em;
    box-shadow: 3px 5px 0 $light-black;
  }
}
</style>
