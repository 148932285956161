import router from '@/router'

export default {
  state: {
    creation: undefined
  },

  mutations: {
    'SET_CREATION' (state, id) {
      state.creation = id
    }
  },

  actions: {
    async 'INIT_GALLERY' ({ state, commit, dispatch, getters }, id) {
      const ids = await dispatch('GET_ALL_NODES_IDS', { variant: 'creation', dataLevel: 'full' })
      if (isNaN(id)) {
        dispatch('OPEN_CREATION', { id: ids[ids.length - 1] })
      } else {
        dispatch('DISPLAY_CREATION', id)
      }
      return dispatch('GET_NODES', { ids, dataLevel: 'full' })
    },

    'DISPLAY_CREATION' ({ state, commit, dispatch, getters }, id) {
      commit('SET_CREATION', id)
      commit('ADD_HISTORY_ENTRIES', [id])
    },

    async 'OPEN_CREATION' ({ state, commit, dispatch }, { id, map = true }) {
      if (router.currentRoute.name !== 'gallery-view') {
        router.push({ name: 'gallery-view', params: { id }, hash: map ? '#map' : '' })
      } else {
        router.push({ params: { id } })
      }
    }
  },

  getters: {
    creations: (state, getters, rootState) => {
      const ids = rootState.ids.creation
      if (ids === undefined) return
      const nodes = ids.map(id => rootState.nodes[id])
      if (nodes.some(node => node === undefined)) return
      return nodes
    },

    creation: (state, getters, rootState) => {
      if (state.creation === undefined || state.creation === null) return state.creation
      return rootState.nodes[state.creation]
    }
  }
}
