<template lang="html">
  <div class="page" :class="'page-' + slug">
    <div v-if="!noClose" class="btn-close-wrapper">
      <button-close @click="$emit('close')" />
    </div>

    <div class="page-wrapper" v-html="page.content" />

    <div class="page-footnotes" v-if="page.notes">
      <div
        v-for="note in page.notes" :key="`note-${note.number}`"
        :id="`note-${note.number}`"
      >
        <div v-if="note.content" class="footnote-content" v-html="note.content"  />

        <div
          v-if="note.links"
          class="footnote-child-list"
        >
          <node-view-title
            v-for="link in note.links" :key="link.id"
            :node="getLinkObj(link)"
            link
            @open-node="onFootnoteLinkClick(link)"
          >
            <dot-button
              :variant="link.variant"
              class="mr-2"
              active hovered
            >
              {{ $t('variants.' + link.variant) }}
            </dot-button>
          </node-view-title>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRelation } from '@/store/utils'
import { NodeViewTitle } from '@/components/nodes'

export default {
  name: 'PageView',

  components: {
    NodeViewTitle
  },

  props: {
    page: { type: Object, default: undefined },
    slug: { type: String, required: true },
    noClose: { type: Boolean, default: false }
  },

  methods: {
    getLinkObj (link) {
      if (link.preTitle || link.italTitle) return link
      if (link.type === 'prod' && link.parents && link.parents.length) return link.parents[0]
      return link
    },

    addFootnotes () {
      const links = this.$el.querySelectorAll('.page-wrapper a')
      links.forEach((link, i) => {
        const number = parseInt(link.hash.replace('#', ''))
        if (!isNaN(number) && this.page.notes.find(note => note.number === number)) {
          link.classList.add('page-footnote-link')
          link.id = `footnote-${this.slug}-${number}`
          link.setAttribute('href', 'javascript:;')
          link.dataset.number = number
          link.textContent = i + 1
          if (link.parentElement.nodeName === 'SUP') {
            link.parentElement.replaceWith(link)
          }
          link.onclick = this.onFootnoteClick

          this.addFootnoteContent(link, number)
        }
      })
    },

    addFootnoteContent (link, i) {
      const note = document.getElementById(`note-${i}`)
      const noteContainer = document.createElement('DIV')
      noteContainer.classList.add('footnote')
      noteContainer.id = link.id + '-content'
      noteContainer.appendChild(note)
      // noteContainer.innerHTML = this.page.notes.find(note => note.number === i).content
      link.insertAdjacentElement('afterend', noteContainer)
    },

    onFootnoteClick (e) {
      const container = document.getElementById(e.target.id + '-content')
      if (container) {
        container.classList.toggle('show')
      }
    },

    onFootnoteLinkClick (node) {
      this.$store.dispatch('OPEN_NODE', getRelation(node))
    }
  },

  mounted () {
    if (this.page.notes) {
      const ids = this.page.notes.filter(note => (note.links)).reduce((ids, note) => {
        note.links.forEach((link) => {
          ids.push(link.id)
          if (link.parents && link.parents.length) {
            ids.push(link.parents[0].id)
          }
        })
        return ids
      }, [])
      this.$store.dispatch('GET_NODES', { ids, dataLevel: 'initial' })
      this.addFootnotes()
    }
  }
}
</script>

<style lang="scss">
.page {
  font-size: 1rem;
  position: relative;

  .btn-close-wrapper {
    position: sticky;
    z-index: 2;
    float: right;
    height: 0;
    right: 0;
    top: $node-view-spacer-sm-y;

    @include media-breakpoint-up($size-bp) {
      top: $node-view-spacer-y;
    }

    .btn-close {
      position: absolute;
      padding: 0.5rem;
      width: 2.25rem;
      height: 2.25rem;
      top: -.5rem;
      right: -.5rem;
      background-color: $white;
    }
  }

  @include media-breakpoint-up($size-bp) {
    font-size: 1.5rem;
  }

  padding: $node-view-spacer-sm-y $node-view-spacer-sm-x;

  @include media-breakpoint-up($size-bp) {
    padding: $node-view-spacer-y $node-view-spacer-x;
  }

  p {
    margin-bottom: 1em;
  }

  blockquote {
    margin: 0 0 50px 50px;

    @include media-breakpoint-up($size-bp) {
      margin: 50px 0 110px 180px;
    }
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 90vh;
  }

  .page-footnotes {
    display: none;
  }

  &-footnote-link {
    display: inline-block;
    text-align: center;
    background-color: $black;
    color: $white;
    font-weight: $font-weight-bold;
    font-size: 0.8em;
    font-style: normal;
    text-decoration: none;
    border-radius: 1em;
    padding: 0 .5em;
    min-width: 1.25em;
    max-height: 1.5em;
    margin: 0 .2em;

    &:hover,
    &:active {
      color: $white;
      text-decoration: none;
    }
  }

  .footnote {
    background-color: $black;
    color: $white;
    font-size: 1.5rem;
    font-style: normal;
    padding: $node-view-spacer-sm-y $node-view-spacer-sm-x;
    margin: $node-view-spacer-sm-y 0;

    @include media-breakpoint-up($size-bp) {
      padding: $node-view-spacer-y $node-view-spacer-x;
      margin: $node-view-spacer-y 0;
    }

    // @include media-breakpoint-up(lg) {
    //   max-width: 50%;
    // }
    &-child-list {
      margin-top: 0;
    }

    &-content + .footnote-child-list {
      margin-top: 1rem;
    }


    &:not(.show) {
      display: none;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    blockquote {
      margin: 0 0 1em 2em;
    }
  }

  &.small {
    font-size: 1rem;
    padding: 0;

    .footnote {
      font-size: 1rem;
      padding: $node-card-spacer-y $node-card-spacer-x;
    }
  }
}
</style>
