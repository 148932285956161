<template>
  <figure class="node-view-figure mt-2 mb-4">
    <div class="node-view-img-wrapper">
      <img :src="node.image.url" :alt="node.image.alt">

      <button-expand @click="onImageExpand" v-b-modal="'modal-image-' + node.id" />
      <b-button variant="creation" class="btn-shadow btn-artwork" @click="onCreationClick">
        {{ $t('text.read-artwork') }}
      </b-button>
    </div>

    <figcaption class="mt-3">
      <node-view-title :node="node" tag="p" class="m-0" />
    </figcaption>
  </figure>
</template>

<script>
import { trim, toCommaList } from '@/helpers/common'
import { NodeViewTitle } from '@/components/nodes'


export default {
  name: 'NodeViewFigure',

  components: {
    NodeViewTitle
  },

  props: {
    node: { type: Object, required: true }
  },

  methods: {
    trim,
    toCommaList,

    onImageExpand () {
      this.$emit('expand-image', this.node.image)
    },

    onCreationClick () {
      this.$store.dispatch('OPEN_CREATION', { id: this.node.id, map: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.node-view-figure {
  .btn-artwork {
    position: absolute;
    right: $node-view-spacer-sm-x;
    bottom: $node-view-spacer-sm-y;
  }

  img {
    max-width: 100%;
  }
}
</style>
