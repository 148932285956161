<template>
  <div
    class="node-view-header-ref" :class="'node-view-header-' + mode"
  >
    <div class="node-view-header-wrapper w-100">
      <div class="nav-container">
        <node-view-child-list
          v-if="mode === 'view' && node.children && node.children.length"
          :children="node.children"
          @open-child="$parent.$emit('open-child', $event)"
        />
        <button-close v-if="mode === 'view'" @click="onClose()" />
      </div>

      <node-view-title
        :node="node" tag="h4"
        block edition url
        class="mr-auto"
        :first-char="firstChar"
      />
    </div>
  </div>
</template>

<script>
import { trim, toCommaList } from '@/helpers/common'
import { NodeViewChildList, NodeViewTitle } from '@/components/nodes'


export default {
  name: 'NodeViewHeaderRef',

  components: {
    NodeViewChildList,
    NodeViewTitle
  },

  props: {
    node: { type: Object, required: true },
    mode: { type: String, required: true },
    firstChar: { type: [String, null], default: null }
  },

  methods: {
    trim,
    toCommaList,

    onClose () {
      this.$parent.$emit('close-node', this.node.id)
    }
  }
}
</script>

<style lang="scss">
.node-view-header {
  &-ref {
    font-family: $font-family-text;
  }

  .nav-container {
      position: relative;
      display: flex;
      float: right;
      @include media-breakpoint-up($layout-bp) {
        right: -13px;
      }
  }


  // ╭─╴╭─┐┌─╮┌─╮
  // │  ├─┤├┬╯│ │
  // ╰─╴╵ ╵╵ ╰└─╯

  &-card {
    h4 {
      font-size: 1.1rem;

      .edition {
        font-size: 0.65rem;
      }

      @include media-breakpoint-up($size-bp) {
        font-size: 2rem;

        .edition {
          font-size: 1.15rem;
        }
      }
    }
  }


  // ╷ ╷╶┬╴┌─╴╷╷╷
  // │╭╯ │ ├─╴│││
  // ╰╯ ╶┴╴╰─╴╰╯╯

  &-view {
    h4 {
      font-size: 1.25rem;

      .edition {
        font-size: 0.75rem;
      }

      @include media-breakpoint-up($size-bp) {
        font-size: 2.625rem;

        .edition {
          font-size: 1.5rem;
        }
      }
    }

    .node-view-child-list {
      display: none;

      @include media-breakpoint-up($layout-bp) {
        display: block;
      }
    }
  }
}
</style>
