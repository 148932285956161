import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Meta from 'vue-meta'
import Vue2TouchEvents from 'vue2-touch-events'
import VueMessages from './messages'
import App from './App'
import router from './router'
import store from './store'


Vue.use(BootstrapVue, {
  BButton: { pill: true },
  BOverlay: { blur: null, spinnerType: 'grow', opacity: 0 },
  BPopover: { boundaryPadding: 0, zIndex: 0 }
})
Vue.use(Meta)
Vue.use(Vue2TouchEvents)
Vue.use(VueMessages)


// Register global components
const requireComponent = require.context('@/components/globals', true, /\.(js|vue)$/i)
// For each matching file name...
requireComponent.keys().forEach((fileName) => {
  // Get the component
  const component = requireComponent(fileName).default
  // Globally register the component
  Vue.component(component.name, component)
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
