<template>
  <div class="btn-zoom">
    <b-button
      variant="dark"
      class="btn-zoom-item"
      @click="$emit('zoom', 1)"
      :disabled="value >= maxZoom"
    >
      <span>+</span>
    </b-button>

    <b-button
      variant="dark"
      class="btn-zoom-item"
      @click="$emit('zoom', -1)"
      :disabled="value <= minZoom"
    >
      <span>–</span>
    </b-button>
  </div>

</template>

<script>
export default {
  name: 'ButtonZoom',

  props: {
    value: { type: Number, required: true },
    minZoom: { type: Number, required: true },
    maxZoom: { type: Number, required: true }
  }
}
</script>

<style lang="scss" scoped>
.btn-zoom {
  position: absolute;
  top: 9px;
  right: 9px;

  @include media-breakpoint-up($size-bp) {
    top: 24px;
    right: 24px;
  }
}

.btn-zoom-item {
  display: block;
  width: $btn-size;
  height: $btn-size;
  padding: 0;
  border: 0;
  font-size: 1.7rem;
  line-height: 1;

  margin: 6px;

  span {
    position: relative;
    top: -3px;
  }

  &:hover:not(.disabled),
  &:focus:not(.disabled) {
    color: $black;
    background-color: $white;
    border: 3px solid $black;

    span {
      top: -6px;
    }
  }
}
</style>
