<template>
  <nav class="node-view-child-list-group">
    <b-collapse :id="'collapse-child-list-' + id" v-model="visible">
      <b-list-group>
        <b-list-group-item
          v-for="child in orderedChildren" :key="child.id"
          :variant="child.variant"
          href="javascript:;"
          @click="$parent.$emit('open-child', { childId: child.id })"
        >
          {{ $t('variants.' + child.variant) }}
        </b-list-group-item>
      </b-list-group>
    </b-collapse>

    <b-button
      class="btn-children"
      :class="visible ? null : 'collapsed'"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="'collapse-child-list-' + id"
      @click.stop="visible = !visible"
      variant="dark"
      block
    >
      {{ $t('children')}} <span class="collapse-icon" :class="{ collapsed: !visible }">></span>
    </b-button>
  </nav>
</template>

<script>
import { orderByVariant } from '@/helpers/common'

export default {
  name: 'NodeViewChildListGroup',

  props: {
    children: { type: Array, required: true },
    id: { type: Number, required: true }
  },

  data () {
    return {
      visible: window.innerWidth > 769
    }
  },

  computed: {
    orderedChildren () {
      return orderByVariant(this.children)
    }
  }
}
</script>

<style lang="scss" scoped>
.node-view-child-list-group {
  position: relative;
  z-index: 1;

  .list-group {
    font-family: $font-family-base;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up($size-bp) {
      font-size: 1.75rem;
    }
  }

  .btn-children {
    display: flex;
    border-radius: 0 !important;
    font-weight: $font-weight-bold;
    padding: $list-group-item-padding-y $list-group-item-padding-x;

    .collapse-icon {
      font-weight: inherit;
      margin-left: auto;
      transform: translate(15px, 0px) rotate(270deg);

      &.collapsed {
        transform: translate(-3px, 0px) rotate(90deg) ;
      }
    }

    @include media-breakpoint-up('tb') {
      display: none;
    }
  }
}
</style>
