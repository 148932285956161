<template>
  <div
    class="node-view-header-prod" :class="'node-view-header-' + mode"
  >
    <div class="node-view-header-wrapper d-flex w-100">
      <h4 class="mr-auto">
        <div class="node-view-header-type">
          {{ $t('variants.' + node.variant) }} {{ withOrigin ? $t('from') : '' }}
        </div>

        <div v-if="withOrigin" class="node-view-header-parent">
          <node-view-title :node="node.parents[0]" edition tag="div" />
        </div>
      </h4>

      <button-close v-if="mode === 'view'" @click="onClose()" />
    </div>
  </div>
</template>

<script>
import { trim, toCommaList } from '@/helpers/common'
import { NodeViewTitle } from '@/components/nodes'


export default {
  name: 'NodeViewHeaderProd',

  components: {
    NodeViewTitle
  },

  props: {
    node: { type: Object, required: true },
    mode: { type: String, required: true },
    showOrigin: { type: Boolean, required: true },
    firstChar: { type: [String, null], default: null }
  },

  computed: {
    parent () {
      if (!this.node || !this.node.parents) return
      return this.node.parents.find(parent => parent.variant === 'depart')
    },

    withOrigin () {
      return (this.showOrigin || this.mode === 'card') && this.node.parents && this.node.parents.length
    }
  },

  methods: {
    trim,
    toCommaList,

    onClose () {
      this.$parent.$emit('close-node', this.node.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.node-view-header {
  &-prod {
    font: {
      family: $font-family-base;
      font-weight: $font-weight-bold;
      size: inherit;
      line-height: inherit;
    }

    @each $color, $value in $theme-colors {
      .node-view-#{$color} & {
        color: darken($value, 32%);
      }
    }
  }

  &-parent {
    font-family: $font-family-text;
  }

  &-card {
    h4 {
      font-size: 0.8rem;

      @include media-breakpoint-up($size-bp) {
        font-size: 1.25rem;
      }
    }
  }

  &-view {
    h4 {
      font-size: 1rem;

      @include media-breakpoint-up($size-bp) {
        font-size: 1.4525rem;
      }
    }
  }

  .btn-close {
    position: relative;
    display: flex;
    float: right;

    @include media-breakpoint-up($layout-bp) {
      right: -13px;
    }
  }
}
</style>
