<template>
  <nav class="node-view-child-list" :class="{ 'smartphone': smartphone }">
    <ul>
      <li
        v-for="(child, i) in orderedChildren" :key="child.id"
        class="node-view-child-list-item"
      >
        <dot-button
          @click="onOpen(child.id)"
          :variant="child.variant"
          :active="activeNodes.includes(child.id)"
          dot-only
          :pos="smartphone ? getDir(i) : 'left'"
        >
          {{ $t('variants.' + child.variant) }}
        </dot-button>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import { orderByVariant } from '@/helpers/common'

export default {
  name: 'NodeViewChildList',

  props: {
    children: { type: Array, required: true },
    smartphone: { type: Boolean, default: false }
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapGetters(['activeNodes']),

    orderedChildren () {
      return orderByVariant(this.children)
    }
  },

  methods: {
    getDir (index) {
      const len = this.children.length
      if (index === 0) return 'right'
      if (index === this.children.length - 1) return 'left'
      return 'middle'
    },

    onOpen (childId) {
      this.$emit('open-child', { childId })
    }
  },

  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.node-view-child-list {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &-item {
    margin-right: 20px;
    line-height: 0;
  }

  &.smartphone {
    padding: $node-view-spacer-sm-y $node-view-spacer-sm-x;
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 1;

    ul {
      justify-content: space-between;
    }

    @include media-breakpoint-up($layout-bp) {
      display: none;
    }
  }
}
</style>
