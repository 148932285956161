<template>
  <b-button
    class="dot-btn"
    :class="['dot-btn-' + variant, { 'active': active, 'show': show, 'hide': dotOnly }]"
    v-bind="$attrs" v-on="$listeners"
    :active="active"
    :variant="variant"
    @mouseover="updateShow(true)"
    @mouseleave="updateShow(false)"
    @focus="updateShow(true)"
    @blur="updateShow(false)"
    @click="onClick"
  >
    <span :class="pos">
      <slot name="default" />
    </span>
  </b-button>
</template>

<script>
export default {
  name: 'DotButton',

  props: {
    variant: { type: String, required: true },
    active: { type: Boolean, default: false },
    hovered: { type: Boolean, default: false },
    dotOnly: { type: Boolean, default: false },
    pos: { type: String, default: 'left' }
  },

  data () {
    return {
      show: this.hovered
    }
  },

  methods: {
    onClick (e) {
      this.$el.blur()
    },

    updateShow (show) {
      this.show = this.hovered || show
    }
  }
}
</script>

<style lang="scss" scoped>
@each $color, $value in $theme-colors {
  .dot-btn-#{$color} {
    @include dot-button-variant($value, $value);
  }
}

.dot-btn {
  word-break: keep-all;
  white-space: pre;

  &:not(.show),
  &.hide {
    height: 1.1875rem;
    width: 1.1875rem;
    padding: 0;

    &.dot-btn-depart {
      height: 1.875rem;
      width: 1.875rem;
    }

    span {
      display: none;
    }
  }

  &.hide:hover {
    span {
      pointer-events: none;
      position: absolute;
      display: block;
      border-radius: 50rem;
      padding: 0 $input-btn-padding-x;
      line-height: 1.5;

      &.left {
        transform: translate(calc(-100% + 1.1875rem), -50%);
      }

      &.right {
        transform: translate(-2px, -50%);
      }

      &.middle {
        transform: translate(calc(-50% + #{1.1875rem / 2}), -50%);
      }
    }

    @each $color, $value in $theme-colors {
      &.dot-btn-#{$color} {
        span {
          background-color: lighten($value, 15%);
        }
        &:active,
        &.active {
          span {
            background-color: $value;
          }
        }
      }
    }
  }
}
</style>
