<template>
  <component
    :is="tag"
    class="node-view-title"
    :class="{ block }"
  >
    <component
      :is="link ? 'a' : 'div'"
      @click="onTitleClick"
      class="node-view-title-container"
    >
      <slot name="default" />
      <span>
        <strong>{{ authors }}</strong>,
        <button-url v-if="url && node.piece && node.piece.url" :link="node.piece" />
        <button-url v-if="url && node.link && node.link.url" :link="node.link" />
      </span>
      <span>
        <span v-if="node.preTitle" v-html="' ' + trim(node.preTitle) + ','" />
        <em v-html="' ' + trim(node.italTitle || node.title) || 'pas de titre ital'" />
        <span v-if="(edition && node.edition) || (!noDate && node.date)">, </span>
      </span>
      <span v-if="!noDate && node.date" class="edition">
        {{ node.date.start }}
      </span>
      <div v-if="edition && node.edition" class="edition">
        {{ node.edition.map(ed => ed.name).join(' ') }}
      </div>
    </component>
  </component>
</template>

<script>
import { trim, toCommaList } from '@/helpers/common'


export default {
  name: 'NodeViewTitle',

  props: {
    node: { type: Object, required: true },
    tag: { type: String, default: 'h6' },
    link: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    edition: { type: Boolean, default: false },
    noDate: { type: Boolean, default: false },
    url: { type: Boolean, default: false },
    firstChar: { type: [String, null], default: null }
  },

  data () {
    return {
    }
  },

  computed: {
    authors () {
      if (!this.firstChar || this.node.authors.length < 2) return toCommaList(this.node.authors)
      const authors = [...this.node.authors].sort((a, b) => a.last_name.localeCompare(b.last_name))
      const first = this.node.authors.filter(author => author.last_name.startsWith(this.firstChar))
      first.forEach((author) => {
        const index = authors.indexOf(author)
        authors.splice(index, 1)
        authors.unshift(author)
      })
      return toCommaList(authors)
    }
  },

  methods: {
    trim,
    toCommaList,

    onTitleClick (e) {
      if (this.link) {
        e.stopPropagation()
        this.$emit('open-node')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.node-view-title {
  font-weight: $font-weight-normal;

  a {
    text-decoration: none;
    cursor: pointer;
  }

  &.block &-container {
    > * {
      display: block;
    }
  }
}
</style>
