<template>
  <footer class="nodes-history no-events-container" v-if="history.length">
    <b-dropdown
      variant="outline-dark"
      dropup no-flip no-caret
      boundary="main"
      class="no-events-container"
      @shown="scrollToEnd"
      @hide="shown = false"
    >
      <template #button-content>
        <span class="collapse-icon" :class="{ collapsed: shown }">></span>
        {{ $t('history') }}
      </template>

      <b-dropdown-text class="dropdown-item-arrow left">
        <b-button variant="link" @click="onArrowClick('left')" :disabled="leftDisabled">&lt;</b-button>
      </b-dropdown-text>

      <b-dropdown-item-button
        v-for="node in history" :key="'history-' + node.id"
        @click="onOpenNode(node)"
      >
        <div
          class="font-weight-bold"
          :class="'text-' + (node.variant === 'depart' ? 'black' : node.variant)"
        >
          {{ $t('variants.' + node.variant) }}
        </div>
        <node-view-title
          :node="node.type === 'ref' ? node : node.parents[0]"
          edition block
          tag="div"
        />
      </b-dropdown-item-button>

      <b-dropdown-text style="width: 70px;" aria-hidden="true" class="ml-auto" />

      <b-dropdown-text class="dropdown-item-arrow right">
        <b-button variant="link" @click="onArrowClick('right')" :disabled="rightDisabled">&gt;</b-button>
      </b-dropdown-text>
    </b-dropdown>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

import { getRelation } from '@/store/utils'
import { NodeViewTitle } from '@/components/nodes'


export default {
  name: 'NodesHistory',

  components: {
    NodeViewTitle
  },

  data () {
    return {
      leftDisabled: false,
      rightDisabled: false,
      shown: false
    }
  },

  computed: {
    ...mapGetters(['history'])
  },

  methods: {
    onOpenNode (node) {
      this.$store.dispatch('OPEN_NODE', getRelation(node))
    },

    updateBtnDir () {
      const container = this.$el.querySelector('.dropdown-menu')
      this.leftDisabled = container.scrollLeft < 5
      this.rightDisabled = container.scrollLeft + container.offsetWidth + 5 > container.scrollWidth
    },

    scrollToEnd () {
      const container = this.$el.querySelector('.dropdown-menu')
      container.scrollTo({ left: container.scrollWidth })
      this.updateBtnDir()
      this.shown = true
    },

    onArrowClick (dir) {
      const container = this.$el.querySelector('.dropdown-menu')
      const width = container.offsetWidth
      const items = Array.from(container.querySelectorAll('.dropdown-item'))
      let left = 0
      if (dir === 'right') {
        const nextItem = items.find(item => {
          return item.offsetLeft + 300 > container.scrollLeft + width
        })
        if (nextItem) {
          left = nextItem.offsetLeft + 370 - width
        }
      } else {
        const nextItem = items.reverse().find(item => {
          return item.offsetLeft < container.scrollLeft
        })
        if (nextItem) {
          left = nextItem.offsetLeft - 70
        }
      }
      container.scrollTo({ left, behavior: 'smooth' })
      setTimeout(this.updateBtnDir, 500)
    }
  }
}
</script>

<style lang="scss">
.nodes-history {
  margin: 0;

  .dropdown {
    width: 100%;

    @include media-breakpoint-up($size-bp) {
      height: 100%;
      display: block;
    }
  }

  .dropdown-toggle {
    background-color: $white;
    border-radius: 0 !important;
    padding: .5rem;
    font-weight: $font-weight-bold;
    text-align: left;
    border-bottom: 0;

    @include media-breakpoint-up($size-bp) {
      position: absolute !important;
      bottom: 0;
      right: 2rem;
      z-index: 1035;
    }
  }

  .dropdown-menu {
    margin-left: 0;
    margin-bottom: 0 !important;
    max-width: 100vw;

    @include media-breakpoint-down($size-bp-down) {
      width: 100vw;
      max-height: calc(100vh - 38px);
      overflow-y: auto;
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
    }

    @include media-breakpoint-up($size-bp) {
      width: 100vw;
      transform: none !important;
      top: 70% !important;
      border-left: 0;
      border-right: 0;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      overflow-y: hidden;
      scrollbar-width: none;

      &.show {
        display: flex;
      }
    }

    .dropdown-item {
      padding: $node-view-spacer-sm-x !important;
      color: $black;
      white-space: normal;

      &:hover {
        background-color: $white;
      }

      @include media-breakpoint-up($size-bp) {
        width: 300px;
      }
    }

    .dropdown-item-arrow {
      display: none;
      position: sticky;
      background-color: $white;

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }

      .b-dropdown-text {
        height: 100%;
        padding: 0;

        .btn {
          padding: 0;
          height: 100%;
          text-decoration: none;
          font-size: 3.5rem;
          width: 70px;
        }
      }

      @include media-breakpoint-up($size-bp) {
        display: block;
      }
    }

    .edition {
      font-size: .7rem;
      margin-top: .15rem;
    }
  }

  @include media-breakpoint-up($size-bp) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .collapse-icon {
    margin-right: .25rem;
    font-weight: 700;
  }
}
</style>
