import messages from './fr.json'


function getMessage (keyedPath, alt) {
  let message
  try {
    message = keyedPath.split('.').reduce((parent, key) => {
      return parent[key]
    }, messages)
  } catch {
    // Silence errors like accessing a key on `undefined`.
  }
  return message === undefined
    ? alt === undefined ? keyedPath : alt
    : message
}


/*
 * Allows separtion of static text content from its templates.
 */
export default class VueMessages {
  static install (Vue) {
    // Adds `this.$t('path.to.string', 'alt')` to every instances.
    Vue.prototype.$t = getMessage

    // Adds `v-t="'path.to.string'"` directive to every elements.
    Vue.directive('t', {
      bind (el, { value }) {
        el.textContent = getMessage(value)
      }
    })
  }
}
