<template>
  <b-modal
    :id="id"
    modal-class="modal-image"
    hide-footer hide-header
  >
    <template #default="{ close }">
      <div class="modal-image-wrapper">
        <button-expand expanded @click="close()" />
        <img
          v-if="image"
          :src="image.url" :alt="image.alt"
          @click="close()"
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'FullscreenModal',

  props: {
    image: { type: Object, default: null },
    id: { type: String, required: true }
  }
}
</script>

<style lang="scss">
.modal-image {
  z-index: 1100;

  .modal {
    &-dialog {
      max-width: 100%;
      height: 100%;
      margin: 0;
    }

    &-content {
      height: 100%;
      border: 0;
      background-color: transparent;
      pointer-events: none;
    }

    &-body {
      position: relative;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .modal-image-wrapper {
        pointer-events: auto;
        position: relative;

        &:not(:hover) .btn-expand {
          display: none;
        }
      }

      .btn-expand {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
      }

      img {
        max-width: 100vw;
        max-height: 100vh;
        display: block;
      }
    }
  }
}
</style>
