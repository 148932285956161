<template>
  <div
    class="node-book"
  >
    <section
      class="node-book-stack"
      :style="`--count: ${nodes.length - 1}; --h: ${stackHeight}px;`"
    >
      <div
        v-for="({ parent, children }, i) in nodes" :key="parent.id"
        class="node-book-stack-item node-book-stack-item-parent splitted no-events-container"
        :class="{ active: i === nodes.length - 1 }"
        :style="`--nth: ${i};`"
      >
        <node-view-child-list
          v-if="parent.children && parent.children.length"
          :children="parent.children"
          smartphone
          @open-child="$emit('open-node', { parentId: parent.id, ...$event })"
        />

        <node-view
          :ref="'parent-' + parent.id"
          :node="parent"
          type="ref"
          mode="view"
          @open-node="$emit('open-node', $event)"
          @open-child="$emit('open-node', { parentId: parent.id, ...$event })"
          @close-node="$emit('close-node', { parentId: $event })"
          @click.native="onNodeViewClick($event, i)"
        />

        <section
          class="node-book-stack no-events-container"
          :style="`--count: ${children.length }; --h: ${getChildrenStackHeight(children.length)}px;`"
        >
          <div
            v-for="(child, j) in children" :key="child.id + '-cont'"
            class="node-book-stack-item"
            :class="{ active: j === children.length - 1 && i === nodes.length - 1 }"
            :style="`--nth: ${j}`"
          >
            <node-view
              :ref="'child-' + child.id"
              :key="child.id"
              :node="child"
              mode="view"
              type="prod"
              @open-node="$emit('open-node', $event)"
              @close-node="$emit('close-node', { parentId: parent.id, childId: $event })"
              @click.native="onNodeViewClick($event, i, j)"
            />
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import { NodeViewChildList, NodeView } from '@/components/nodes'


export default {
  name: 'NodeBook',

  components: {
    NodeViewChildList,
    NodeView
  },

  props: {
    nodes: { type: Array, required: true }
  },

  computed: {
    stackHeight () {
      return Math.min(150 / (this.nodes.length - 1), 75)
    }
  },

  methods: {
    getChildrenStackHeight (childrenLen) {
      return this.stackHeight / childrenLen || this.stackHeight
    },

    onNodeViewClick (e, parentIndex, childIndex) {
      if (e.target.classList.contains('btn') || window.innerWidth < 1024) return
      const lastChildIndex = this.nodes[parentIndex].children.length - 1
      const parentIsLast = parentIndex === this.nodes.length - 1
      const childIsLast = childIndex !== undefined
        ? childIndex === lastChildIndex
        : true
      if (parentIsLast && childIsLast) return
      const ids = { parentId: this.nodes[parentIndex].parent.id }
      if (!parentIsLast) {
        this.$refs['parent-' + ids.parentId][0].onScroll({}, true)
      }
      if (childIndex !== undefined) {
        ids.childId = this.nodes[parentIndex].children[childIndex].id
      }
      if (childIndex !== undefined || lastChildIndex > -1) {
        const childId = childIndex !== undefined ? ids.childId : this.nodes[parentIndex].children[lastChildIndex].id
        console.log(childId)
        this.$refs['child-' + childId][0].onScroll({}, true)
      }
      this.$emit('select-node', ids)
    }
  },

  updated () {
    const smarphoneStickyNav = document.querySelector('.node-view-child-list.smartphone')
    const offset = smarphoneStickyNav ? smarphoneStickyNav.getBoundingClientRect().height : 0
    const nodeviews = this.$el.querySelectorAll('.node-view')
    document.querySelector('main').scrollTo(0, nodeviews[nodeviews.length - 1].offsetTop - offset)
  }
}
</script>

<style lang="scss" scoped>
.node-book {
  min-height: 100%;

  background-color: rgba($white, .5);

  @include media-breakpoint-up($layout-bp) {
    height: 100%;

    // ╭─╴╶┬╴╭─┐╭─╴╷ ╭
    // ╰─╮ │ ├─┤│  ├┴╮
    // ╶─╯ ╵ ╵ ╵╰─╴╵ ╵

    &-stack {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      &-item {
        width: 100%;
        height: 100%;
        position: absolute;
        top: calc(var(--h) * var(--nth));
        height: calc(100% - (var(--h) * var(--nth)));

        ::v-deep > .node-view {
          border-top: $line;
        }

        &-parent > .node-view {
          border-right: $line;
        }

        &:not(.active) {
          ::v-deep > .node-view {
            cursor: pointer;
            overflow-y: hidden;

            &:not(:hover) {
              .node-view-header-wrapper {
                opacity: .5;
              }
            }
            .node-view-body-wrapper,
            .node-view-footer-wrapper {
              opacity: .5;
              pointer-events: none;
            }

            .node-view-header {
              z-index: 0;
              padding-top: $node-view-spacer-y * .75;
              padding-bottom: $node-view-spacer-y * .75;
            }

            .node-view-header-ref {
              h4 {
                font-size: 1.2rem;
              }

              .edition {
                font-size: 0.8rem;
                display: none;
              }

              .btn-url {
                width: 20px;
                height: 20px;
              }
            }

            .node-view-child-list {
              display: none;
            }

            @each $color, $value in $theme-colors {
              &-#{$color} {
                background-color: lighten($value, 15%);

                .node-view-header,
                .node-view-footer {
                  background-color: lighten($value, 15%);
                }
              }
            }

            &:hover {
              .node-view-header {
                z-index: 100;
                border-bottom: $line !important;
                padding-bottom: calc(#{$node-view-spacer-y * .75} - 2px);

                h4 {
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        &.splitted {
          display: flex;
          flex-basis: 50%;
        }
      }
    }
  }
}
</style>
