import router from '@/router'


export default {
  state: {
    sheet: null
  },

  mutations: {
    'SET_KIT_SHEET' (state, id) {
      state.sheet = id
    }
  },

  actions: {
    async 'INIT_KIT' ({ state, commit, dispatch, getters }) {
      const ids = await dispatch('GET_ALL_NODES_IDS', { variant: 'kit', dataLevel: 'partial' })
      return dispatch('GET_NODES', { ids, dataLevel: 'partial' })
    },

    async 'INIT_KIT_VIEW' ({ state, commit, dispatch, getters }, id) {
      await dispatch('INIT_KIT')
      const node = await dispatch('GET_NODE', { id, dataLevel: 'full' })
      commit('SET_KIT_SHEET', id)
      commit('ADD_HISTORY_ENTRIES', [id])
      return node
    },

    async 'OPEN_KIT_NODE' ({ state, commit, dispatch }, id) {
      if (router.currentRoute.name !== 'kit-view') {
        router.push({ name: 'kit-view', params: { id } })
      } else {
        router.push({ params: { id } })
      }
    },

    'CLOSE_KIT_NODE' ({ state, commit }) {
      router.push({ name: 'kit' })
      commit('SET_KIT_SHEET', null)
    }
  },

  getters: {
    sheets: (state, getters, rootState) => {
      const ids = rootState.ids.kit
      if (ids === undefined) return
      const nodes = ids.map(id => rootState.nodes[id])
      if (nodes.some(node => node === undefined)) return
      return nodes
    },

    sheet: (state, getters, rootState) => {
      if (state.sheet === undefined || state.sheet === null) return state.sheet
      return rootState.nodes[state.sheet]
    }
  }
}
